import { SettingsService } from './../../providers/settings.service';
import { ICardLoadProgress, ILoadProgress, ICard } from './../../../models/card';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { Component, Input, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

const CORRECT_APP_POSTER = environment.type === 'web' ? 'cardPosterWeb' : 'cardPosterMobile';
const CORRECT_APP_CONTENT = environment.type === 'web' ? 'cardContentWeb' : 'cardContentMobile';
@Component({
  selector: 'app-card-video',
  templateUrl: './card-video.component.html',
  styleUrls: ['./card-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardVideoComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;

  @Input() posterSrc: string;
  @Input() videoSrc: string;
  @Output() videoCanBePlayed = new EventEmitter<boolean>();

  public videoCanPlay: boolean = false;
  private videoLoaded: boolean = false;

  private cardLoadStart: number;
  private cardLoadFinish: number;
  private cardShowedTime: number;
  public cardShowCardLoadDelta: number = 0;
  public cardLoadProgress: ILoadProgress[] = [];
  public cardChangedSubscription: Subscription;

  constructor(public cardService: CardsService, public settingsService: SettingsService) { }

  ngOnInit(): void {

    this.cardChangedSubscription = this.cardService.onCardChangedInVisibleZone.subscribe((changedCards: ICard[]) => {
      if(changedCards[1][CORRECT_APP_CONTENT] === this.videoSrc && changedCards[1][CORRECT_APP_POSTER] === this.posterSrc) {
        console.log('TRIGGERED CURRENT CARD!: ', this.videoSrc);

        if(this.cardLoadProgress.find(card => {
          return card.eventName === 'CardCanPlayThrough'
        })) return;

        this.cardShowedTime = performance.now();
      }
    });
  }

  getProgressLabel(): string {
    const progress = this.cardLoadProgress;
    return Math.floor(progress[progress.length-1].progressMark*1000)/10 + '% in ' + progress[progress.length-1].time + 's';
  }

  getLagLabel(): string {
    if(isNaN(this.cardShowCardLoadDelta)) return '0s';
    return this.cardShowCardLoadDelta.toString().concat('s');
  }

  onLoadStart(event: any): void {
    this.videoCanBePlayed.emit(false);
    const videoName = this.target.nativeElement.currentSrc.substring(this.target.nativeElement.currentSrc.indexOf('/preview/') + '/preview/'.length, this.target.nativeElement.currentSrc.length);
    console.log('** LOAD STARTED: ', videoName, ' ** ', event );
  }

  onLoadedMetadata(): void {
    this.cardLoadStart = performance.now()
    const videoName = this.target.nativeElement.currentSrc.substring(this.target.nativeElement.currentSrc.indexOf('/preview/') + '/preview/'.length, this.target.nativeElement.currentSrc.length);
    console.log('** METADATA LOADED: ', videoName, ' ** ' );
  }

  onLoadedData(): void {
    const videoName = this.target.nativeElement.currentSrc.substring(this.target.nativeElement.currentSrc.indexOf('/preview/') + '/preview/'.length, this.target.nativeElement.currentSrc.length);
    console.log('** ON DATA LOADED: ', videoName, ' ** ' );
  }

  onImgLoad(): void {
    // QUICK WORKAROUND TO HIDE SPLASH SCREEN WHEN FIRST CARD LOADED (KEEPING IN MIND, THAT FIRST CARD WILL TRIGGER IT);
    this.settingsService.hideSplashScreen();
  }

  onCanPlayThrough(): void {
    const currentVideoElement = this.target.nativeElement;
    const videoName = this.target.nativeElement.currentSrc.substring(this.target.nativeElement.currentSrc.indexOf('/preview/') + '/preview/'.length, this.target.nativeElement.currentSrc.length);
    let calculatedProgressMark: number;
    // console.log(currentVideoElement.buffered.start(0));
    // console.log(currentVideoElement.buffered.end(0));
    // console.log(currentVideoElement.duration);

    // avoid adding additional events on scroll;
    if(this.cardLoadProgress.find(card => {
      return card.eventName === 'CardCanPlayThrough'
    })) return;

    try {
      currentVideoElement.buffered.end(0);
    } catch (error) {
      console.log('** CAN PLAY INIT EARLIER, THAT BUFFERED.END(): ', videoName, Math.floor(performance.now() - this.cardLoadStart)/1000);
      calculatedProgressMark = 0.0000001;
    }

    if(calculatedProgressMark !== 0.0000001) calculatedProgressMark = currentVideoElement.buffered.end(0) / currentVideoElement.duration
    this.cardLoadProgress.push({
      progressMark: calculatedProgressMark,
      time: Math.floor(performance.now() - this.cardLoadStart)/1000,
      eventName: 'CardCanPlayThrough'
    })

    this.cardShowCardLoadDelta = Math.floor(performance.now() - this.cardShowedTime)/1000;
    console.log('** CAN PLAY: ', videoName, ' ** progress: ', this.cardLoadProgress);
    this.videoCanPlay = true;
    this.videoCanBePlayed.emit(true);
  }

  onProgress(event: any): void {
    const currentVideoElement = this.target.nativeElement;
    if(isNaN(currentVideoElement.duration)) return;
    if(currentVideoElement.buffered?.length === 0 ) return;

    this.cardLoadProgress.push({
      progressMark: currentVideoElement.buffered.end(0) / currentVideoElement.duration,
      time: Math.floor(performance.now() - this.cardLoadStart)/1000
    })

    if(this.target.nativeElement.buffered.end(0) >= this.target.nativeElement.duration*0.98 && !this.videoLoaded) {
      this.cardLoadFinish = performance.now()

      const videoName = this.target.nativeElement.currentSrc.substring(this.target.nativeElement.currentSrc.indexOf('/preview/') + '/preview/'.length, this.target.nativeElement.currentSrc.length);
      console.log('** LOAD FINISHED: ', videoName, ' ** time: ', (Math.floor(this.cardLoadFinish - this.cardLoadStart))/1000, 's', this.cardLoadProgress );
      this.videoLoaded = true;
      this.cardService.onVideoLoadFinished({cardName: videoName, progress: this.cardLoadProgress});
    }
  }

  getPosterSrc(): string {
    if(this.posterSrc === undefined) return;
    if(this.settingsService.devicePlatform === 'ios') return this.posterSrc;
    if(environment.type === 'web') return this.posterSrc;
    return this.posterSrc.replace('.webp','.jpeg');
  }

  isVideoFullyLoaded(): boolean {
    return this.target.nativeElement.buffered.end(0) === this.target.nativeElement.duration
  }

  ngOnDestroy(): void {
    this.cardChangedSubscription?.unsubscribe();
  }

}
