<app-webnav  #navbarComponent (navbarButtonClick)="onNavbarBtnClicked($event)" (webpanelActionClick)="handleWebpanelClick($event)"></app-webnav>
<div [@cardFeedAnimation]="currentAnimationState"  class="shared-content-page" ngClass.lt-sm="shared-content-page-lt-sm" ngClass.lt-md="shared-content-page-lt-md" ngClass.gt-sm="shared-content-page-gt-sm" ngClass.gt-lg="shared-content-page-gt-lg">

    <div class="nodata-wrapper" *ngIf="isNoDataRecieved">
      <h1 class="nodata-title">Something went wrong :(</h1>
      <h3 class="nodata-desc">{{errorData}}</h3>
    </div>

    <app-web-models-card #collectionCardsFeed [feedIsVisible]="!isModelFeedVisible" [cardsRaw]="cards" [feedModificator]="sharedFeedModificator" (cardVisibilityChanged)="onCardVisibilityChanged($event)" (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" ></app-web-models-card>
    <app-web-collection-details #collectionDetailsComponent  *ngIf="isCollectionDetailsShown" [@feedWebCollectionDetailsAnimation]="isCollectionDetailsShown"  (@feedWebCollectionDetailsAnimation.done)="onCollectionDetailsAnimationDone($event)" (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)"   [sourceCollection]="collectionDetailsSource" ></app-web-collection-details>
    <div [@homeOverscrollAnimation]="isModelFeedVisible" class="shared-content-overscroll-background" *ngIf="isModelFeedVisible"></div>

    <app-web-models-card @modelFeedAnimation [feedModificator]="{isSharingModelFeed: true}" #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedVisible" [startPosition]="modelFeedCardStartPosition"[feedIsVisible]="isModelFeedVisible" [cardsRaw]="modelFeedCards"  [feedMode]="CARDS_MODE.COMPOSITE_FEED" (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" (cardVisibilityChanged)="onCardVisibilityChanged($event)" ></app-web-models-card>

 </div>
