import { WebNavService } from './../webnavigation.service';
import { SettingsService } from './../../shared/providers/settings.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, Inject, HostListener, AfterViewInit, DoCheck } from '@angular/core';
import { ActivatedRoute, Params, Router,} from '@angular/router';
import { CARD_PURCHASE_STATUS, CARD_TYPE, ICard, INavpanelAction, NAVPANEL_ACTION_TYPE, SUBSCRIPTION_STATUS } from 'src/app/models/card';

import { Observable, Subscription, of, switchMap, throttleTime } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { inOutAnimation } from 'src/app/shared/providers/animations-list';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { WebUploadService } from '../webupload.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { WebDialogData, WebpopupdialogComponent } from '../webpopupdialog/webpopupdialog.component';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { trigger, transition, animate, style } from '@angular/animations';
import { ICardCollection } from 'src/app/models/collection';
import { WebstripepaymentComponent } from '../webstripepayment/webstripepayment.component';
import { StripeService } from 'ngx-stripe';

import { User } from 'firebase/auth';
import { WebsubscriptionComponent } from '../websubscription/websubscription.component';
import { WebaccountComponent } from '../webaccount/webaccount.component';
import { CustomRollbar, RollbarService } from 'src/app/shared/providers/rollbarlog.service';
import { AuthpopupComponent } from 'src/app/shared/components/authpopup/authpopup.component';


@Component({
  selector: 'app-webnav',
  templateUrl: './webnav.component.html',
  styleUrls: ['./webnav.component.scss'],
  animations: [
    inOutAnimation,
    trigger('fadeLeave', [
      transition(':leave', [
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ opacity: 0 }))
      ])
    ])
  ],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlapTrigger: false,
        yPosition: 'above',
        backdropClass: 'webmenu-backdrop',
        overlayPanelClass: 'webmenu-overlay'
      }
    }
  ]
})

export class WebnavComponent implements OnInit, OnDestroy, DoCheck {

  @Input() sourceCollection: ICardCollection;
  @Output() navbarButtonClick = new EventEmitter<NAVBAR_BTN>();
  @Output() webpanelActionClick = new EventEmitter<INavpanelAction>();


  private lastLikedBtnElementTarget: HTMLButtonElement;

  public environment = environment;

  public FOOTER_MODE = FOOTER_MODE;
  public NAVBAR_BTN = NAVBAR_BTN;
  public SUBSCRIPTION_STATUS = SUBSCRIPTION_STATUS;

  public firebaseUserSubscription: Subscription;
  public currentCardSubscription: Subscription;
  public uploadSubscriptionNotification: Subscription;

  public currentUser: User;
  public currentVisibleCard: ICard;

  public collectionLikeClick: EventEmitter<ICardCollection> = new EventEmitter<ICardCollection>();
  public latestUpdate: any;
  public authpopupLink: MatDialogRef<AuthpopupComponent>;
  public webSubscriptionDialogSub: Subscription;
  public statusWatcherInFirebaseSubscription: Subscription;
  private authPopUpCloseSubscription: Subscription;
  private lastLikeActionType: CARD_TYPE;

  @HostListener('window:beforeunload', ['$event'])
  handleClose(event: Event): boolean {
    console.log('uploadService.getIsNoUploadInProgress():', this.uploadService.getIsNoUploadInProgress());
    if (!this.uploadService.getIsNoUploadInProgress()) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  constructor(
    private stripeService: StripeService,
    private router: Router,
    private readonly route: ActivatedRoute,
    public cardService: CardsService,
    @Inject(RollbarService) private rollbar: CustomRollbar,
    public webNavService: WebNavService,
    public firebaseAuth: FirebaseAuthService,
    public userDataService: UserDataService,
    public utilityService: UtilityFunctionsService,
    public dialog: MatDialog,
    public settingsService: SettingsService,
    public uploadService: WebUploadService) {

      this.collectionLikeClick.pipe(
        throttleTime(500, undefined, { leading: true, trailing: false }),
        switchMap((col) => this.handleCollectionLikeClick(col))
      ).subscribe((response) => { });

      this.firebaseUserSubscription = this.firebaseAuth.firebaseUser.subscribe((user: User) => {
        if (user) {
          this.authpopupLink?.close();
        }
      });

      this.firebaseAuth.getUserUid().then((uid) => {
        if(this.statusWatcherInFirebaseSubscription) this.statusWatcherInFirebaseSubscription.unsubscribe();
        this.statusWatcherInFirebaseSubscription = this.firebaseAuth.createSubscriptionForStatusChangesInFirebase().subscribe(data => {
          this.latestUpdate = data;
          console.log(`StatusWatcherInFirebaseSubscription Latest Update: ${JSON.stringify(this.latestUpdate)}`);
          this.cardService.setSubForStatusSubscription().then(() => {
            this.firebaseAuth.isSubscriptionRelatedBtnDisabled = false;
          });
        }, error => {
          console.error('Error:', error);
        });
      });

      this.uploadSubscriptionNotification = this.uploadService.uploadSubscriptionRequestNotifier.subscribe((notification: string) => {
        console.log('uploadSubscriptionRequestNotifier notification:', notification);
        this.openSubscriptionDialog();
      });
    }

  async ngOnInit() {
      this.currentCardSubscription = this.cardService.currentCardSubject.subscribe((card: ICard) => {
        this.currentVisibleCard = card;
      });

      this.authPopUpCloseSubscription = this.webNavService.webAuthPopupClosed.subscribe((event) => {
        if(this.lastLikeActionType) {
          this.userDataService.removeFromSavedCollections(this.userDataService.generateCollectionFromCard(this.cardService.getCurrentCard()), false);
          this.lastLikeActionType = undefined;
          }
      });

  }


  ngDoCheck(): void {
    this.recalculateFooterShift();
  }


  public recalculateFooterShift(): void {
    const buyWebBtns = document.querySelectorAll('app-webnav .main-web-btn');
    const footMains = document.querySelectorAll('app-webnav .foot-main');
    const webFootRows = document.querySelectorAll('app-webnav .webfoot-row');

    if (buyWebBtns.length === 0 || footMains.length === 0) return;

    const lastBuyWebBtn = buyWebBtns[buyWebBtns.length - 1];
    const lastFootMain = footMains[footMains.length - 1] as HTMLElement;
    const lastWebFootRow = webFootRows[webFootRows.length - 1] as HTMLElement;

    const lastBuyWebBtnWidth = lastBuyWebBtn.getBoundingClientRect().width;
    const lastDistance = lastBuyWebBtn.getBoundingClientRect().right - lastFootMain.getBoundingClientRect().left - lastBuyWebBtnWidth / 2;

    lastWebFootRow.style.setProperty('--mainbtn-nav-shift', lastDistance + 'px');

}



  async onSignInWithAppleClicked()  {
    try {
      await this.firebaseAuth.loginWithAppleWeb();
    } catch (error) {

    }
  }

  public dropped(files: any) {
    this.uploadService.uploadFilesWithPreProcessing(files);
  }

  public goToLibrary() {
    this.router.navigateByUrl('/');
  }

  public onDeleteAccEventWeb() {
    this.webNavService.deleteAccRequest();
  }

  public getIsThisWebNavShouldBeFullScreenOnSmallDevices(): boolean {
    return this.webNavService.footerMode === FOOTER_MODE.PORTFOLIO || this.webNavService.footerMode === FOOTER_MODE.EDIT_COLLECTION
  }

  public checkIfUserAbleToAddFromLibraryAndModifyNavpanelItem(): void {
    const indexToModify = this.webNavService.editCollectionAddWebPanelItems.findIndex((item) => item.actionType === NAVPANEL_ACTION_TYPE.ADD_FROM_LIBRARY);
    if(indexToModify >= 0) {
      if(this.userDataService.allUserCards.length === 0) this.webNavService.editCollectionAddWebPanelItems[indexToModify].optionalData = {disabled: true}
      else this.webNavService.editCollectionAddWebPanelItems[indexToModify].optionalData = {disabled: false}
    }
  }

  public async navbarBtnClick(btn: NAVBAR_BTN, event: Event): Promise<void> {
    console.log('navbarBtnClick:', btn);
    event.stopPropagation();
      switch (true) {
        case btn === NAVBAR_BTN.SHARE:
          this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
          break;
          // const shared = await this.cardService.shareDataRequest(this.sourceCollection.collectionId, 'collection');
          // // Copy the shared ID to the clipboard
          // if (navigator.clipboard) {
          //   const sharedLink = `${environment.firebaseConfig.authDomain}/shared/${shared.shared_id}?shared_type=collection`;
          //   await navigator.clipboard.writeText(sharedLink);
          //   this.utilityService.showImportantErrorSnackBar(
          //     `Collection successfully shared - id: ${shared.shared_id} (Copied to clipboard)`,
          //     250
          //   );
          // } else {
          //   console.error('Clipboard API not supported');
          //   this.utilityService.showImportantErrorSnackBar(
          //     `Collection successfully shared - id: ${shared.shared_id}`,
          //     250
          //   );
          // }
          //   break;
        case btn === NAVBAR_BTN.DOWNLOAD_SELECTED_MODELS:
        case btn === NAVBAR_BTN.SEARCH:
          this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
          // this.navbarButtonClick.emit(btn)
          return;
        case btn === NAVBAR_BTN.UPLOAD:
          this.handleWebpanelActionClick({actionName: 'Upload', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL});
          return;
        case btn === NAVBAR_BTN.ACCOUNT:
          if(this.webNavService.isUserAuthenticated) this.openAccountPopup();
          else this.handleUnauthorizedUserActionDetected();
          break;
        // case btn === NAVBAR_BTN.HOME:
        //   if(!this.isUserAuthenticated) {
        //     this.handleUnauthorizedUserActionDetected();
        //     break;
        //   }
        //   this.navbarButtonClick.emit(btn)
        //   break;
        default:
          this.navbarButtonClick.emit(btn)
          break;
      }
  }

  public onLessButtonClicked(event: Event): void {
    event.stopPropagation();
    this.cardService.triggerLessBtnSubject();
    // this.router.navigateByUrl('/home');
  }

  public handleWebpanelActionClick(action: INavpanelAction) {

       if(this.webNavService.isUserAuthenticated === false) {
        this.handleUnauthorizedUserActionDetected();
        return;
       }

      switch (true) {
        case action.actionType === NAVPANEL_ACTION_TYPE.NEW_COLLECTION:
          this.openDialog();
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.STRIPE_HOSTED_CHECKOUT:
          console.log('Stripe Hosted Checkout');
          this.stripeHostedCheckoutRequest();
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.STRIPE_PAYMENT:
          this.openPaymentDialog();
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.SHOW_SUBSCRIBE:
          this.openSubscriptionDialog();
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.FREE_TRIAL_ACTIVATION:
          this.freeTrialActivation();
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL:
            // CHECK IS USER AVAILABLE FOR UPLOAD
            if(this.uploadService.basicCheckIfUserCanUpload()) {
              setTimeout(() => {
                this.webNavService.activateDragZoneForceShow();
                }, 170);
            }
            break;
        case action.actionType === NAVPANEL_ACTION_TYPE.CREATE_COLLECTION_DONE:
          const collectionTitle = action.optionalData ? action.optionalData : this.userDataService.getNewCollectionDefaultName()
          const emptyCollection = this.utilityService.createEmptyCollection(collectionTitle);
          this.userDataService.createUserCollection(emptyCollection);
          this.cardService.createCollectionPostRequest(emptyCollection).then((resp) => {
            this.utilityService.showSnackBar('Collection created', 500);
            const colId = resp.collection_id;
            this.userDataService.updateUserCollectionId(emptyCollection.collectionId, colId);

            if(this.router.url.indexOf('editcollection') >= 0) {
              const queryParamsUpdated: Params = { collectionId: colId };
              this.router.navigate(['/editcollection'], {
                relativeTo: this.route,
                queryParams: queryParamsUpdated,
                queryParamsHandling: 'merge'
              });
            }
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.SIGN_OUT:
          this.webNavService.signOut();
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.SETTINGS:
          this.router.navigateByUrl('/service');
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.VIEW_ACCOUNT:
          this.openAccountPopup();
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.EDIT_COLLECTION:
          this.webpanelActionClick.emit(action);
          this.webNavService.footerMode = FOOTER_MODE.ACTIVE_EDIT_COLLECTION
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.DELETE_COLLECTION:
          this.webpanelActionClick.emit(action);
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.ADD_FROM_LIBRARY:
          this.webpanelActionClick.emit(action);
          break;
        case action.actionType === NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:

          this.webpanelActionClick.emit(action);
          break;
        default:
          break;
      }
      console.log(action.actionName);
  }

  public openDialog() {
    const dialogConfig: WebDialogData = {
      heading: 'Give your collection a name.',
      mainBtnTitle: 'Create',
      inputPlaceHolder: this.userDataService.getNewCollectionDefaultName(),
      inputValue: ''
    }
    const dialogRef = this.dialog.open(WebpopupdialogComponent, {data: dialogConfig, restoreFocus: false, panelClass: 'webpopup-overlay'});

    const sub = dialogRef.componentInstance.webpopupdialogueMainBtnClick.subscribe((collectionName: string) => {
      console.log('Collection to create:', collectionName);
      this.handleWebpanelActionClick({actionName: 'Create', actionType: NAVPANEL_ACTION_TYPE.CREATE_COLLECTION_DONE, optionalData: collectionName});
      this.dialog.closeAll();
      sub.unsubscribe();
    });

  }

  public handleUnauthorizedUserActionDetected() {
    this.triggerShowAuthPopup();
  }

  public triggerShowAuthPopup() {
    this.authpopupLink = this.dialog.open(AuthpopupComponent, {data: {}, restoreFocus: false, panelClass: 'webpopup-overlay'},);
  }

  public openAccountPopup() {
    const dialogRef = this.dialog.open(WebaccountComponent, {restoreFocus: false, panelClass: ['webpopup-overlay','web-account']});

    const sub = dialogRef.componentInstance.webAccountPopupEvent.subscribe((action) => {
      this.handleWebpanelActionClick(action);
      sub.unsubscribe();
    });

  }

  public redirectToWebLandingPage(): void {
    this.router.navigateByUrl('/');
  }

  public openSubscriptionDialog(disabled: boolean = false) {
    const dialogRef = this.dialog.open(WebsubscriptionComponent, {data: disabled, restoreFocus: false, panelClass: 'compact-webpopup-overlay'});

    this.webSubscriptionDialogSub = dialogRef.componentInstance.websubscriptionclicked.subscribe((action) => {
      this.handleWebpanelActionClick(action);
    });
  }

  public closeSubscriptionDialog() {
    this.dialog.closeAll();
  }

  public removeLikeTouchedClass(): void {
    if(this.lastLikedBtnElementTarget) {
      this.lastLikedBtnElementTarget.classList.remove('touched');
    }
  }

  public onLikeButtonClicked(card: ICard, event: Event) {
    event.stopPropagation();
    // Workaround to trigger animation only when 'like' click button was performed;
    const eventTarget: any = event.currentTarget;
    this.lastLikedBtnElementTarget = eventTarget;
    eventTarget.classList.add('touched');

    if(this.webNavService.isUserAuthenticated === false) {
      this.handleUnauthorizedUserActionDetected();
      this.lastLikeActionType = CARD_TYPE.COLLECTION;
      this.userDataService.addToSavedCollections(this.userDataService.generateCollectionFromCard(this.cardService.getCurrentCard()), false);

      return;
    }

    const temporaryCollection: ICardCollection = this.userDataService.generateCollectionFromCard(card);
    this.collectionLikeClick.next(temporaryCollection)

    if(this.userDataService.isCollectionExitsInSaved(temporaryCollection)) {
      this.utilityService.showImportantSnackBar('Added to Library.', 0, 750);
    } else {
      this.utilityService.showImportantSnackBar('Removed from Library.', 0, 750);
    }

    // this.webpanelActionClick.emit(action);
  }

  private handleCollectionLikeClick(collection: ICardCollection): Observable<{ action: string }> {
    if (this.userDataService.isCollectionExitsInSaved(collection)) {
      this.userDataService.removeFromSavedCollections(collection);
      return of({ action: 'removed' });
    } else {
      this.userDataService.addToSavedCollections(collection);
      return of({ action: 'added' });
    }
  }

  public stripeHostedCheckoutRequest() {
    this.rollbar.info(`Stripe Subscription Request ${this.firebaseAuth.currentUser.uid}`, {subscriptionStatus: {
      status: this.firebaseAuth.currentUser.subscriptionStatus,
      uploadsNumber: this.firebaseAuth.currentUser.uploadsNumber,
      uploadNumberLimit: this.firebaseAuth.currentUser.uploadNumberLimit
    }});
    this.cardService.createCheckOutSession().then((res: any) => {
      console.log(res);
      // , lineItems:[{price: 'price_1P8Hhk2K1MDKKgy5nW5mc3PL', quantity: 1}]
      this.stripeService.redirectToCheckout({ sessionId: res.sessionId }).subscribe((result) => {
        console.log(result);
        if (result.error) {
          alert(result.error.message);
        }
      });
    }).catch((error) => {
      this.utilityService.showImportantErrorSnackBar(`Error occured on subscription process: ${JSON.stringify(error.error)}`, 500);
      this.dialog.closeAll();
    });
  }

  public freeTrialActivation(): void {
    this.cardService.activateTrialPostRequest().then((resp) => {
      this.firebaseAuth.currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.TRIAL;
      this.utilityService.showImportantSnackBar('Free Trial Activated', 350);
    }).catch((error) => {
      this.utilityService.showImportantErrorSnackBar(`Error occured on free trial activation: ${JSON.stringify(error.error)}`, 500);
    });
    // this.currentUser.isTryForFreeClicked = true;
  }



  public openPaymentDialog() {

    const dialogRef = this.dialog.open(WebstripepaymentComponent, {data: undefined, restoreFocus: false, panelClass: 'webpopup-overlay'});
  }

  getProperAccountItems(): INavpanelAction[] {
    if(environment.production) return this.webNavService.profileProdAccountWebPanelItems;
    if(environment.production === false) return this.webNavService.proftileDevAccountWebPanelItems;
  }

  getProperCollectionDetailsWebPanelItems(): INavpanelAction[] {
    const col = this.cardService.getCurrentCard();
    if(this.userDataService.isCollectionExitsInSaved(col.id)) return this.webNavService.collectionDetailsWebPanelItemsLiked;
    if(!this.userDataService.isCollectionExitsInSaved(col.id)) return this.webNavService.collectionDetailsWebPanelItemsUnliked;
  }

  public isSaved(card: ICard): boolean {
    if(card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED) return true;
    if(card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED) return false;
    if(card.cardType === CARD_TYPE.MODEL) return this.userDataService.isCardExitsInSaved(card);
    if(card.cardType === CARD_TYPE.COLLECTION) return this.userDataService.isCollectionExitsInSaved(this.userDataService.generateCollectionFromCard(card));
  }

  ngOnDestroy() {
    if(this.firebaseUserSubscription) this.firebaseUserSubscription.unsubscribe();
    if(this.statusWatcherInFirebaseSubscription) this.statusWatcherInFirebaseSubscription.unsubscribe();
    if(this.uploadSubscriptionNotification) this.uploadSubscriptionNotification.unsubscribe();
    if(this.webSubscriptionDialogSub) this.webSubscriptionDialogSub.unsubscribe();
    if(this.authPopUpCloseSubscription) this.authPopUpCloseSubscription.unsubscribe();
   this.currentCardSubscription.unsubscribe()
  }


}

