import { WebCollectionDetailsComponent } from '../web-collection-details/web-collection-details.component';
import { feedCollectionDetailsAnimation, feedWebCollectionDetailsAnimation, homeOverscrollAnimation, inOutAnimation, modelFeedAnimation, navbarmodelFeedAnimation } from '../../shared/providers/animations-list';
import { UtilityFunctionsService } from '../../shared/providers/utility-functions.service';
import { ModelCardRendered, CARDS_MODE, INavpanelAction, NAVPANEL_ACTION_TYPE, ICardAction, CARD_CONTROL_BTN, Cards_Page_State } from '../../models/card';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { ICard, NoDataMessage } from 'src/app/models/card';
import { ModelsCardComponent } from 'src/app/shared/components/models-card/models-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import { fullscreenCardAnimation, cardFeedAnimation,  } from 'src/app/shared/providers/animations-list';
import { AnimationEvent } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { ICardCollection } from 'src/app/models/collection';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { WebNavService } from '../webnavigation.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { environment } from 'src/environments/environment';
import { WebModelsCardComponent } from '../web-models-card/web-models-card.component';
import { WebnavComponent } from '../webnav/webnav.component';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';



@Component({
  selector: 'app-webhome',
  templateUrl: './webhome.component.html',
  styleUrls: ['./webhome.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fullscreenCardAnimation,
    cardFeedAnimation,
    feedWebCollectionDetailsAnimation,
    modelFeedAnimation,
    homeOverscrollAnimation,
    navbarmodelFeedAnimation,
    inOutAnimation
],
})
export class WebHomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('collectionCardsFeed', { static: false })
  public collectionCardsFeed: WebModelsCardComponent;

  @ViewChild('modelCardsFeed', { static: false })
  public modelCardsFeedRef: WebModelsCardComponent;

  @ViewChild('collectionDetailsComponent', { static: false })
  public collectionDetailsComponent: WebCollectionDetailsComponent;

  @ViewChild('navbarComponent', { static: false })
  public webnav: WebnavComponent;

  public currentAnimationState: string = Cards_Page_State.CARD_FEED;
  public CARDS_MODE = CARDS_MODE;
  public FOOTER_MODE = FOOTER_MODE;

  public cards: ICard[] = [];
  public homeCollections: ICardCollection[] = [];

  public cardsNoData: NoDataMessage;

  public collectionDetailsSource: ICardCollection;

  public lessBtnSubscription: Subscription;
  public moreBtnSubscription: Subscription;

  public modelFeedCardStartPosition: number;

  public isModelFeedVisible: boolean = false;
  public isDeepLinkActivated: boolean = false;

  public navbarAnimationState: string = 'default';
  public modelFeedCards: ModelCardRendered[];

  public isCollectionDetailsShown: boolean = false;
  public isCollectionDetailsHiding: boolean = false;
  public currentCard: ICard;

  public environment: any = environment

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    public webNavService: WebNavService,
    public firebaseAuth: FirebaseAuthService,
    public cardService: CardsService,
    public utilityService: UtilityFunctionsService,
    public settingService: SettingsService
    ) {}

  async ngOnInit() {
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    this.collectionDetailsSource = JSON.parse(JSON.stringify(this.utilityService.collectionDetailsMock));

    this.route.queryParamMap.subscribe(async queryParams => {
      const modelId = queryParams.get('modelId');
      const collectionId = queryParams.get('collectionId');
      if(modelId) {
        const model = await this.cardService.getModelById(modelId);
          if(model) {
            this.isDeepLinkActivated = true;
            console.log('MODEL RECIEVED BY ID : ', model);
            this.isModelFeedVisible = true;
            this.modelFeedCardStartPosition = 0;
            this.modelFeedCards = model
          }
        }
      if(collectionId) {
        // SETTIMEOUT TEMPORARY HACK
          const collections = await this.cardService.getCollectionByIdRequest(collectionId);
          // const collection = this.cardService.getCollectionFromHomeFeedById(collectionId);
          if(collections) {
            this.isDeepLinkActivated = true;
            console.log('COLLECTION RECIEVED BY ID : ', collections);
            this.isCollectionDetailsShown = true;
            this.collectionDetailsSource = collections[0];
            this.webNavService.footerMode = FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED;
          }
      }
     });

    this.homeCollections = await this.cardService.getCollectionFeed();
    this.cards = this.cardService.convertCollectionsToCardsFeed(this.homeCollections);

    this.cardService.homeFeedGetTime = performance.now();
    console.log('PERF_ INIT HOME homeFeedGetTime TIME:', this.cardService.homeFeedGetTime);

    this.currentCard = this.cards[0];
    this.cardsNoData = {text: 'Something went wrong with cardFeed: no cards data'};

    this.moreBtnSubscription = this.cardService.moreBtnSubject.subscribe((currentCard: ICard) => {
      console.log(`Home feed more click btn happened, ${currentCard?.cardTitle}`);
      this.collectionCardsFeed.removeLikeTouchedClass();
      // this.webnav.removeLikeTouchedClass();
      this.cardService.collectionDetailsInitTimeStart = performance.now();
      // To avoid situation, when card from 'ModelFeed' set as current card on back btn click;
      if(this.cardService.getCollectionCardSourceByTitle(currentCard.cardContentMobile)) {
        this.cardService.setLastCollectionCard(currentCard);
      }
      this.collectionDetailsSource = this.utilityService.setPropsForCollectionDetailsBasedOnCard(currentCard, this.collectionDetailsSource);
      this.collectionDetailsSource.collectionCards = this.getCardsFromCollectionById(currentCard.id)

      this.modelFeedCards = this.collectionDetailsSource.collectionCards;
      this.isCollectionDetailsShown = true;
      // this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
      this.webNavService.footerMode = FOOTER_MODE.COLLECTION_DETAILS;
    })


    this.lessBtnSubscription = this.cardService.lessBtnSubject.subscribe(async (currentCard: ICard) => {
      console.log('LessBtnSubscription triggered')
        if(this.webNavService.footerMode === FOOTER_MODE.COLLECTION_DETAILS || this.webNavService.footerMode === FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED) {
          this.collectionDetailsComponent.removeLikeTouchedClass();
          this.webnav.removeLikeTouchedClass();
        }
        this.isCollectionDetailsShown = false;
        // this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME;
        this.webNavService.footerMode = FOOTER_MODE.DEFAULT;

        // this.navbarAnimationState = 'default';
        this.navbarAnimationState = 'transitionToFeed';
        this.collectionCardsFeed.setActiveComponentCardToCardService();
    })

    this.cardService.homeBasicInitTime = performance.now();
    console.log('PERF_ INIT HOME (End of NgOnInit) homeBasicInitTime TIME:', this.cardService.homeBasicInitTime);

    if(this.firebaseAuth.getFirstTimeScrolledAnyFeed()) return;
    else {
      setTimeout(() => {
        this.utilityService.showImportantSnackBar('Scroll to discover more collections', 1000, 3000);
        this.collectionCardsFeed.showAnimationScrollForNextModelCard()
      }, 2000);
    }
  }

  ngAfterViewInit(): void {
  }

  private getCardsFromCollectionById(id?: string): ICard[] {
    if(id) {
      const targetCol = this.homeCollections.find(col => {
        return col.collectionId === id;
      })
      return targetCol?.collectionCards
    } else return []
  }

  public onCollectionClicked(collectionNonConverted: ModelCardRendered) {
    // this.currentFooterMode = this.currentFooterMode === FOOTER_MODE.DEFAULT || this.currentFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    // this.currentFooterMode = this.currentFooterMode === FOOTER_MODE.DEFAULT || this.currentFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onModelCardPressed() {
    // this.webNavService.footerMode = FOOTER_MODE.CARD_FULLSCREEN;
    // this.currentFooterMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public handleWebpanelClick(action: INavpanelAction) {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:
        // DIRTY HACK: Because updates of navpanel items are very quickly and before navpanel hidden you see different variant;
         setTimeout(() => {
          this.collectionDetailsComponent.onLikeButtonClicked(this.collectionDetailsSource);
         }, 250);
        break;
    }
  }

  public onNavbarBtnClicked(navbarBtn: NAVBAR_BTN) {
    console.log('clicked ', navbarBtn)
    switch (navbarBtn) {
      case NAVBAR_BTN.TO_TOP:
        this.collectionDetailsComponent.nativeScrollToTopLight();
        break;
      case NAVBAR_BTN.SEARCH:
        console.log('not implemented')
        break;
      case NAVBAR_BTN.BUY:
        console.log('BUY BTN CLICKED');
        break;
      case NAVBAR_BTN.BACK:
          // this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.SELECT_ALL:
        this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.MORE_OPTIONS:
        this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.NOT_IMPL:
        this.utilityService.notImplemented();
        break;
      default:
        break;
    }
  }

  public onCardVisibilityChanged(card: ModelCardRendered) {
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
  }

  public onNavbarAnimationDone(event: AnimationEvent): void {
    console.log('onNavbarAnimationDone start: ', event?.toState);
  }

  public onCollectionDetailsAnimationStart(event: AnimationEvent): void {
    console.log('onCollectionDetailsAnimationStart start');
    // if(event.toState === 'void') {
    //   this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME;
    // }
  }

  public onCollectionDetailsAnimationDone(event: AnimationEvent): void {
    console.log('onCollectionDetailsAnimationStart done');
    if(event.toState !== 'void') {
      this.cardService.collectionDetailsInitTimeEnd = performance.now();
      console.log('COLLECTION DETAILS INIT TIME: ', this.cardService.collectionDetailsInitTimeEnd - this.cardService.collectionDetailsInitTimeStart);
    }

    // additional check to fire this logic only when user stay on home page;
    if(event.toState === 'void' && this.router.url.includes('/home')) {
      // this.currentFooterMode = FOOTER_MODE.DEFAULT;
      this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
      this.collectionCardsFeed.startPlayVisibleCollectionCard();
      this.navbarAnimationState = 'default';
    }
  }

  public onCollectionDetailsThumbClick(thumbNumber: number): void {
    this.modelFeedCards = this.collectionDetailsSource.collectionCards;
    console.log('this.modelFeedCards: ', this.modelFeedCards)

    this.collectionCardsFeed.disposeAllVideosExceptCurrent();
    this.cardService.modelFeedInitStart = performance.now();

    // this.navbarAnimationState = 'modelFeed';
    this.modelFeedCardStartPosition = thumbNumber;
    console.log('on thumb click (home)');
    // this.currentFooterMode = FOOTER_MODE.DEFAULT;
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    this.isModelFeedVisible = true;
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:
          this.collectionDetailsComponent.dispatchLikeClick();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_COLLECTION:
        this.collectionDetailsComponent.onShareBtnClicked(this.collectionDetailsSource);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_MODEL:
        this.modelCardsFeedRef.onShareBtnClicked(this.cardService.getCurrentCard());
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;
      default:
        break;
    }
  }

  public onCardCollectionControlBtnClicked(action: ICardAction): void {
    console.log('onCardCollectionControlBtnClicked: ', action)
    this.utilityService.triggerHaptics();
    switch (action.controlAction) {
      case CARD_CONTROL_BTN.ANONYMOUS_USER:
        this.webnav.handleUnauthorizedUserActionDetected();
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;
      case CARD_CONTROL_BTN.CONTROL_GO_TO_DETAILS:
        if (!this.isModelFeedVisible) {
          this.currentCard = action.card;
          this.cardService.triggerMoreBtnSubject();
        }
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        if(this.isDeepLinkActivated && this.isCollectionDetailsShown) {
          this.webNavService.footerMode = FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED;
          this.isModelFeedVisible = false;
          break;
        }
        if(this.isDeepLinkActivated && !this.isCollectionDetailsShown) {
          this.isDeepLinkActivated = false;
          this.webNavService.footerMode = FOOTER_MODE.DEFAULT;

          this.isModelFeedVisible = false;
          this.navbarAnimationState = 'default';
          this.collectionCardsFeed.setFirstCardExplicitVisible();
          this.collectionCardsFeed.setActiveComponentCardToCardService();
          break;
        }
          this.webNavService.footerMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.cardService.setCurrentCard(this.currentCard);

          this.isModelFeedVisible = false;
          this.navbarAnimationState = 'default';
        break
      case CARD_CONTROL_BTN.SEARCH:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.isDeepLinkActivated = false;
    this.lessBtnSubscription.unsubscribe();
    this.moreBtnSubscription.unsubscribe();
  }

}
