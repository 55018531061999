import {NgModule} from '@angular/core';
import {RouterModule, Routes, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import { WebLandingComponent } from './web/weblanding/weblanding.component';
import { TermsOfServiceComponent } from './web/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './web/privacy-policy/privacy-policy.component';
import { WeblibraryComponent } from './web/weblibrary/weblibrary.component';
import { WebEditCollectionComponent } from './web/webedit-collection/webedit-collection.component';
import { WebPortfolioComponent } from './web/webportfolio/webportfolio.component';
import { WebuploadsandboxComponent } from './web/webuploadsandbox/webuploadsandbox.component';
import { ServiceSettingsComponent } from './pages/service-settings/service-settings.component';
import { WebHomeComponent } from './web/webhome/webhome.component';
import { Platform } from '@angular/cdk/platform';
import { WebMobileRestrictionGuard } from './shared/providers/mobile-guard';
import { WebSharedUserContentComponent } from './web/web-shared-user-content/web-shared-user-content.component';

const routes: Routes = [
  { path: '', component: WeblibraryComponent, data: { animation: 'libraryPageAnimation' } },
  { path: 'uploadsandbox', component: WebuploadsandboxComponent, canActivate: [WebMobileRestrictionGuard] },
  { path: 'home', component: WebHomeComponent, data: { animation: 'homePageAnimation' }, canActivate: [WebMobileRestrictionGuard] },
  { path: 'shared/:shared_id', component: WebSharedUserContentComponent },
  { path: 'library', component: WeblibraryComponent, data: { animation: 'libraryPageAnimation' }},
  { path: 'editcollection', component: WebEditCollectionComponent, data: { animation: 'editCollectionPageAnimation' }, canActivate: [WebMobileRestrictionGuard] },
  { path: 'portfolio', component: WebPortfolioComponent, data: { animation: 'homePageAnimation' }, canActivate: [WebMobileRestrictionGuard] },
  { path: 'portfolio/:uid', component: WebPortfolioComponent, data: { animation: 'homePageAnimation' }, canActivate: [WebMobileRestrictionGuard] },

  { path: 'service', component: ServiceSettingsComponent, canActivate: [WebMobileRestrictionGuard] },
  { path: 'legal/privacy-policy', component: PrivacyPolicyComponent },  // No guard here
  { path: 'legal/privacy', component: PrivacyPolicyComponent },  // No guard here
  { path: 'legal/terms-of-service', component: TermsOfServiceComponent },  // No guard here
  { path: 'legal/terms', component: TermsOfServiceComponent },  // No guard here
  { path: '**', redirectTo: '' }
];

// const routes: Routes = [
  // {path: '', component: WebLandingComponent},
  // {path: 'ourway', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://about.3dway.io/'} },
  // {path: 'ourway', component: AvailableSoonComponent},

  // {path: 'solutions', component: AvailableSoonComponent},
  // {path: 'pricing', component: AvailableSoonComponent},
  // {path: 'sales', component: ContactSalesComponent},
  // {path: 'support', component: ContactSupportComponent},
  // {path: 'legal', component: AvailableSoonComponent},
  // {path: 'status3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://status.3dway.io'} },
  // {path: 'about3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://about.3dway.io/'} },
  // {path: 'careers3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://careers.3dway.io'} },
  // {path: '404', component: Error404Component},
// ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
  providers: [
    {
        provide: 'externalUrlRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
            window.location.href = (route.data as any).externalUrl;
        }
    }
]
})
export class WebRoutingModule {
  constructor(private platform: Platform) {
  }

}
